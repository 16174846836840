////////////// DATA LOADING ETC ////////////////

// REST calls for loading game data
export const NEW_GAME_LOAD = 'NEW_GAME_LOAD';
export const NEW_GAME_ID_LOAD = 'NEW_GAME_ID_LOAD';
export const LOAD_GAME_DATA = 'LOAD_GAME_DATA';
export const LOAD_GAME_FAILURE = 'LOAD_GAME_FAILURE';
export const LOAD_GAME_SUCCESS = 'LOAD_GAME_SUCCESS';
export const LOAD_GAME_ID_FAILURE = 'LOAD_GAME_ID_FAILURE';
export const LOAD_GAME_ID_SUCCESS = 'LOAD_GAME_ID_SUCCESS';
export const LOAD_CLIPS = 'LOAD_CLIPS';
export const LOAD_CLIPS_SUCCESS = 'LOAD_CLIPS_SUCCESS';
export const LOAD_CLIPS_FAILURE = 'LOAD_CLIPS_FAILURE';
export const LOAD_VOICES = 'LOAD_VOICES';
export const LOAD_VOICES_SUCCESS = 'LOAD_VOICES_SUCCESS';
export const LOAD_VOICES_FAILURE = 'LOAD_VOICES_FAILURE';
export const LOAD_VOICES_ALL_GAME = 'LOAD_VOICES_ALL_GAME';
export const LOAD_VOICES_ALL_GAME_SUCCESS = 'LOAD_VOICES_ALL_GAME_SUCCESS';
export const LOAD_VOICES_ALL_GAME_FAILURE = 'LOAD_VOICES_ALL_GAME_FAILURE';
export const SHUFFLE_CHOICES_ALL_GAME = 'SHUFFLE_CHOICES_ALL_GAME';
export const SHUFFLE_CHOICES = 'SHUFFLE_CHOICES';
export const LOAD_NEXT_GAME_ID_SUCCESS = 'LOAD_NEXT_GAME_ID_SUCCESS';
export const LOAD_NEXT_GAME_ID_FAILURE = 'LOAD_NEXT_GAME_ID_FAILURE';
export const IS_FROM_HOF_CLICK = 'IS_FROM_HOF_CLICK';

// TIMER ACTIONS
export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const TICK_TIMER = 'TICK_TIMER';
export const ZERO_TIMER = 'ZERO_TIMER';
export const OUT_OF_TIME = 'OUT_OF_TIME';
export const INCREMENT_VOX_COUNT = 'INCREMENT_VOX_COUNT';

// BUTTON GAMEPLAY ACTIONS
export const CLICK_ANSWER = 'CLICK_ANSWER';
export const CHECK_ANSWER = 'CHECK_ANSWER';
export const PREP_NEXT_QUESTION = 'PREP_NEXT_QUESTION';
export const GAME_OVER = 'GAME_OVER';
export const SAVE_GAME = 'SAVE_GAME';
export const SAVE_GAME_SUCCESS = 'SAVE_GAME_SUCCESS';
export const SAVE_GAME_FAILURE = 'SAVE_GAME_FAILURE';
export const START_NEXT_GAME = 'START_NEXT_GAME';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
